import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import LoadingScreen from './LoadingScreen';

const Login = lazy(()=>import("./Login"));
const Employee = lazy(()=>import("./employee/Employee"));
const Hr = lazy(()=>import("./hr/Hr"));
const Admin = lazy(()=>import("./admin/Admin"));

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(()=>{
    let userData = JSON.parse(localStorage.getItem("userData"));
    if(userData)
    {
      if(userData.access[userData.access.length-2]==="1")
      {
        navigate("/admin");
      }
      else if(userData.access[userData.access.length-2]==="2")
        {
          navigate("/hr");
        }
        else if(userData.access[userData.access.length-2]==="3")
          {
            navigate("/accounts");
          }
          else if(userData.access[userData.access.length-2]==="4")
            {
              navigate("/employee");
            }
    }
    else
    {
      navigate("/");
    }


      const handleTabClose = (event) => {
        event.preventDefault();
        // Perform any custom logic here, e.g., saving data or sending a request.        
        localStorage.removeItem("userData");
        localStorage.removeItem("pass");
        // Some browsers require this return value to show a confirmation dialog
        event.returnValue = ''; // Required by some browsers for the 'beforeunload' event.
      };
  
      // Add event listener when component mounts
      window.addEventListener('beforeunload', handleTabClose);
  
      // Cleanup the event listener when component unmounts
      return () => {
        window.removeEventListener('beforeunload', handleTabClose);
      };
    }, []);

  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Suspense fallback={<LoadingScreen />}>
            <Routes>
                <Route path="" element={<Login />}  />
                <Route path="/hr/*" element={<Hr />}  />
                <Route path="/employee/*" element={<Employee />}  />
                <Route path="/admin/*" element={<Admin />}  />
            </Routes>
        </Suspense>
    </div>
  )
}
