import React from 'react';
import Lottie from 'lottie-react';
import loadingAnimation from "./loading.json";

export default function LoadingScreen() {
  return (
    <div className='loading-container display-flex-column'>
      <div className="mt-5 w-100 px-0 display-flex mx-0">
        <img src="https://incubeindia.com/incubelandmarks/images/finallogo.PNG" className='w-25 mx-auto' alt="Incube Landmarks Logo" /></div>
        <Lottie animationData={loadingAnimation} className='w-25 mx-auto' />
    </div>
  )
}
