import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './components/Dashboard';
import Popup from './components/Popup';

//admin, hr, accounts, employee
//admin -> 1
//hr -> 2
//accounts -> 3
//employee -> 4

function App() {
  return (
    <div className="container-fluid px-0 m-0 row display-flex">
        <BrowserRouter>
          <Routes>
            <Route path='/*' element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
        {/* <Popup /> */}
    </div>
  );
}

export default App;
